import React, { useContext } from 'react'
import { ContentContext } from '../context/ContentContext'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import BlockText from './blocks/BlockText'
import BlockMedia2 from './blocks/BlockMedia2'
import BlockMediaWText from './blocks/BlockMediaWText'
import BlockVideo from './blocks/BlockVideo'
import Footer from './Footer'


const WorkWrap = styled.div`
	width: 100%;
`


const Work = props => {
	const { pageContent, findPageBySlug } = useContext(ContentContext)
	const { pathname } = useLocation()

	if(!pageContent) {
		return null
	} 
	
	const parts = pathname.split("/")
	if(parts.length !== 3 || parts[1] !== "work") {
		return <div>404</div>
	}
	const content = findPageBySlug(parts[2])

	if(content === undefined) {
		return <div>404</div>
	}

	const blockSwitch = (params, key) => {
		switch(params.sys.contentType.sys.id) {
			case 'blockText' :
				return <BlockText key={key} {...params} />
			case 'blockMedia2' :
				return <BlockMedia2 key={key} {...params} />
			case 'blockMediaWText' :
				return <BlockMediaWText key={key} {...params} />
			case 'blockVideo' :
				return <BlockVideo key={key} {...params} />
			default :
				return <div>Invalid block type found in renderer.</div>
		}
	}
	
	setTimeout(() => {
		window.dispatchEvent(new Event('resize'))
	}, 250);
	
    
	return <WorkWrap>
		<div className="optimal">
		<h1>{content.fields.title}</h1>
		</div>
		{content.fields.content.map((block, i) => {
			return blockSwitch(block, i)
		})}
		
		<Footer />
		</WorkWrap>
}

export default Work