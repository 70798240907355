const vars = {
	colors: {
		white: '#FAFAFA',
		primary: '#FFCD80',
		primaryRgb: '255,205,128',
		alternative: '#80DEEA',
		alternativeRgb: '128,222,234',
		tertiary: '#DDBAFF',
		green: '#90FFD5',
		dark: '#111',
		subtle: '#151515'
	},
	background: {
		borderChance: .25
	},
	funkyBorderChance: .08, 
	maxWidth: '1600px',
	defaultPadding: {
		x: '5vw',
		y: '0px'
	}
}

export default vars