import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import vars from '../helpers/vars'
import SendIcon from '@material-ui/icons/Send'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ErrorIcon from '@material-ui/icons/Error'

const pulse = keyframes`
    0% {
        box-shadow: 0px 0px 15px ${vars.colors.alternative};
        border-bottom: 1px solid ${vars.colors.alternative};
        color: ${vars.colors.alternative};

        ::placeholder {
            color: ${vars.colors.alternative};
        }
    }

    50% {
        box-shadow: 0px 0px 25px ${vars.colors.primary};
        border-bottom: 1px solid ${vars.colors.primary};
        color: ${vars.colors.primary};

        ::placeholder {
            color: ${vars.colors.primary};
        }
    }

    100% {
        box-shadow: 0px 0px 15px ${vars.colors.alternative};
        border-bottom: 1px solid ${vars.colors.alternative};
        color: ${vars.colors.alternative};

        ::placeholder {
            color: ${vars.colors.alternative};
        }
    }
`


const Wrapper = styled.div`
    padding-top: 2em;
    display: flex;
	align-items: center;
	
	svg {
		transition: opacity .4s, transform .3s;
		padding-left: 20px;
		cursor: pointer;
		transform: scale(1);

		&.check {
			padding-left: 0px;
			padding-right: 10px;
		}

		&.inactive {
			opacity: 0;
			pointer-events: none;
			transform: translateX(-100%) scale(.6);
		}
	}

    input {
        padding: 10px;
        background: none;
        border: none;
        font-size: .8em;
        color: ${vars.colors.alternative};
        border-bottom: 1px solid ${vars.colors.alternative};
        outline: none;
        width: 400px;


        ::placeholder {
            color: ${vars.colors.alternative};
            transition: color .2s;
            opacity: .335;
        }

        :focus {
            animation: ${pulse} 2s cubic-bezier(0.83, 0, 0.17, 1) infinite;
            border-radius: 5px;

            ::placeholder {
                color: #FFF;
            }
        }
    }
`

const send = (data, callback) => {
	const endpoint = 'portfolio'

	fetch(`https://pvm-submit-api.herokuapp.com/v1/submit/${endpoint}`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then(res => {
		if(res.status === 200) {
			callback.success()
		} else {
			callback.error()
		}
	}) 
}



const RequestPortfolio = props => {
    const [ email, setEmail ] = useState('')
    const [ isValidEmail, setValid ] = useState(false)
	const [ sent, setSent ] = useState(undefined)

	const handleSend = () => {
		send({email}, { success: () => setSent(true), error: () => setSent(false)})
	}

    const handleChange = e => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const valid = re.test(String(e.target.value).toLowerCase())
        setEmail(e.target.value)
        setValid(valid)
    }



    return <Wrapper>
        {sent !== undefined ? sent === true ? <React.Fragment><CheckBoxIcon className="check" /> <span>Je aanvraag zit in onze inbox!</span></React.Fragment> : <React.Fragment><ErrorIcon className="check" /> <span>Er ging iets niet helemaal goed... <u onClick={() => setSent(undefined)}>Try again.</u></span></React.Fragment> : <React.Fragment><input type="text" onChange={handleChange} value={email} placeholder="E-mailadres" /> <SendIcon onClick={handleSend} className={isValidEmail ? 'active' : 'inactive'} /></React.Fragment>}
    </Wrapper>
}

export default RequestPortfolio