import React from 'react'
import ChapterWrapper from './Chapters'
import Floralove from './love-chapters/Floralove'
import Blocks from './love-chapters/Blocks'
import HelloMyFriend from './love-chapters/HelloMyFriend'
import PVM from './love-chapters/PVM'

const ChapterLove = props => {
	return (
    <React.Fragment>
        <ChapterWrapper contextId="HMF"><HelloMyFriend height={3} {...props.fields.richContent.fields} /></ChapterWrapper>
		<ChapterWrapper contextId="PVM"><PVM height={3} {...props.fields.richContent.fields} /></ChapterWrapper>
        <ChapterWrapper contextId="Floralove"><Floralove height={2} {...props.fields.richContent.fields} /></ChapterWrapper>
		<ChapterWrapper contextId="Bar35 + more"><Blocks height={false} /></ChapterWrapper>
		{/*<ChapterWrapper><Republiq  height={3.5} /></ChapterWrapper>*/}
		
    </React.Fragment>)
}


export default ChapterLove