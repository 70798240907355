import React, { useContext } from 'react'
import ChapterWrapper, { ChapterSplash } from './Chapters'
import ChapterLove from './ChapterLove'
import ChapterTech from './ChapterTech'
import ChapterFilm from './ChapterFilm'
import { ContentContext } from '../context/ContentContext'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import NameDropping from './NameDropping'
import vars from '../helpers/vars'
import { Link } from 'react-router-dom'
import RequestPortfolio from './RequestPortfolio'
import { CustomAnchor } from './Nav'
import Footer from './Footer'
import renderOptions from '../helpers/renderOptions'

export const SectionHeader = styled.h2`
	display: flex;
	align-items: center;
	color: ${vars.colors.primary};
	font-family: nimbus-sans, sans-serif;
	font-weight: bold;
	margin-bottom: 10vh;

	svg {
		transition: opacity .2s;
		height: .7em;
		width: .7em;
		margin-left: .3em;
		opacity: .5;
		cursor: pointer;

		&:hover {
			opacity: .8;
		}
	}

	&.first {
		margin-top: 15vh;
	}

	&.name-dropping {
		font-family: monotalic-narrow, sans-serif;
		font-weight: 100;
		font-size: 100%;
		color: ${vars.colors.alternative};
		margin-bottom: 0em;
	}
`

export const Chunk = styled.div`
	&.alt {
		color: ${vars.colors.alternative};
	}

	&#collabs {
		width: 45%;

		@media all and (max-width: 1200px) {
			width: 75%;
		}

		@media all and (max-width: 1024px) {
			width: 100%;
		}
	}

	span.collab:last-of-type span.space {
		display: none;
	}

	&#name-chunk {
		max-width: 100%;
		padding: 50px 0px;
	}
`

const Hero = styled.div`
	font-size: 200%;
	width: 75%;

	@media all and (max-width: 1024px) {
		width: 100%;
	}
`
const Sub = styled.p`
	width: 75%;

	@media all and (max-width: 1024px) {
		width: 100%;
	}
`

const Outer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Separator = styled.div`
	display: flex;
	align-items: center;
	padding: 5vh 0px 12vh 0px;;

	a {
		text-decoration: none;
		transition: padding .5s cubic-bezier(0.34, 1.56, 0.64, 1);
		color: ${vars.colors.primary} !important;
		padding-right: 25px;

		&:hover {
			padding: 0px 50px 0px 50px;
		}
	}
`

const Stroke = styled.div`
	height: 1px;
	flex-grow: 1;
	background-color: ${vars.colors.primary};
`

const Triangle = styled.div`
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-left: 7px solid ${vars.colors.primary};
`



const Home = () => {
	const { content } = useContext(ContentContext)
	

	const chapterSwitch = (chapter, key) => {
		switch(chapter.fields.chapterId) {
			case 1 :
				return <React.Fragment key={key}>
					<div className="optimal">
						<ChapterSplash>
						<h2>{chapter.fields.title}</h2>
						<h3>{chapter.fields.tagline}</h3>
						{documentToReactComponents(chapter.fields.body)}
						</ChapterSplash>
					</div>
					<ChapterLove {...chapter} />
				</React.Fragment>
			case 2 :
				return <ChapterWrapper contextId="Film" key={key} overflowHidden={true}><ChapterFilm height={3} fields={chapter.fields} /></ChapterWrapper>
			case 3 :
				return <ChapterWrapper contextId="Tech" key={key}><ChapterTech height={4} fields={chapter.fields} /></ChapterWrapper>
			default :
				return null
		}
	}

	return content ? (
		<Outer>
			<div className="optimal">
				<CustomAnchor hash="" noAnchor />
				<Hero className="monotalic">
					{documentToReactComponents(
						content.fields.introductionHero,
						renderOptions
					)}
				</Hero>
				<Sub>{content.fields.introductionSub}</Sub>

				<Separator>
					<Link to="/about" className="description">
						Team &amp; about
					</Link>
					<Stroke />
					<Triangle />
				</Separator>
			</div>

			<NameDropping />

			<div className="optimal bottom-space">
				<Chunk className="alt" id="collabs">
					<SectionHeader className="name-dropping">
						Collaborations
					</SectionHeader>
					<span className="description">
						{documentToReactComponents(
							content.fields.collaborationIntroduction,
							renderOptions
						)}
					</span>
					<p className="description">
						<strong>
							{content.fields.collabs.map((collab, i) => (
								<span key={i} className="collab">
									{collab}
									<span className="space">, </span>
								</span>
							))}
							.
						</strong>
					</p>
				</Chunk>
			</div>

			<CustomAnchor hash="showcase" />
			<div className="optimal">
				<SectionHeader>Showcase</SectionHeader>
			</div>

			{content.fields.chapters.map((chapter, i) => chapterSwitch(chapter, i))}
			<CustomAnchor hash="showcase" noAnchor />
			<div className="optimal alt bottom-space top-gradient">
				<SectionHeader>Portfolio aanvragen</SectionHeader>
				<div className="text">
					Wil je een uitgebreid portfolio met alles erop en eraan ontvangen? Vul
					je e-mailadres in we sturen je zo snel mogelijk aanvullend materiaal.
				</div>
				<RequestPortfolio />
			</div>
			<div className="optimal bottom-space">
				<CustomAnchor hash="process" />
				<SectionHeader>Werkwijze</SectionHeader>
				<div className="text">
					{documentToReactComponents(content.fields.approach, renderOptions)}
				</div>
			</div>

			<Footer />
		</Outer>
	) : null
}


export default Home