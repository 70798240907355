import React, { useState, useContext, useEffect, useRef } from 'react'
import { ScrollContext } from '../Chapters'
import { LayoutContext } from '../../context/LayoutContext'
import BezierEasing from 'bezier-easing'
import styled from 'styled-components'
import { Top, Tagline } from '../../assets/hmf/logo'

const Wrapper = styled.div`
    height: 100%;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;

    display: flex;

    div#ref {
        width: 100%;
        height: 100vh;
        display: flex;
    }

    div.section {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
		align-items: center;
		position: relative;

        &#first {
            background-color: #0068B3;
        }

        &#second {
            background-color: #ED312A;
        }

        &#third {
            background-color: #38BBA8;
		}
		
		img {
			position: absolute;
			object-fit: cover;
			width: 100%;
			height: 100%;
			z-index: 1;
			mix-blend-mode: luminosity;
			opacity: .2;
		}
    }

    svg {
        width: 50%;
        min-width: 125px;
        max-width: 500px;
		height: auto;
		z-index: 2;

		&.tagline {
			margin-top: 10px;
		}

        @media all and (max-width: 700px) {
            min-width: 25px;
        }
    }
`


const HelloMyFriend = props => {
    const { scrolling, progress, entering, screensInWrapper, setScreens, overrideWrapHeight, setOverrideWrapHeight, inView } = useContext(ScrollContext)
    const { viewportWidth } = useContext(LayoutContext)
    const blockRef = useRef(undefined)
    const [ blockHeight, setBlockHeight ] = useState(0)
    const [ blockWidth, setBlockWidth ] = useState(viewportWidth > 1200 ? 33.33 : 50)
	const easing = BezierEasing(0.65, 0, 0.35, 1)
	
	const images = [
		props.media.find(el => el.sys.id === props.specifics.hmf.imagesIds[0]).fields.file.url + '?w=400&h=900',
		props.media.find(el => el.sys.id === props.specifics.hmf.imagesIds[1]).fields.file.url + '?w=400&h=900',
		props.media.find(el => el.sys.id === props.specifics.hmf.imagesIds[2]).fields.file.url + '?w=400&h=900'
	]

	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
    })
    
    useEffect(() => {
        setBlockWidth(viewportWidth > 1200 ? 33.3 : 50)
    }, [viewportWidth])

	useEffect(() => {
        if(blockRef.current && blockRef.current.clientHeight !== blockHeight) {
            setBlockHeight(blockRef.current.clientHeight)
            if(!overrideWrapHeight) {
				setOverrideWrapHeight(blockRef.current.clientHeight)
			}
        }

    }, [easing, progress, blockHeight, overrideWrapHeight, setOverrideWrapHeight])
    
    const peakProgress = (pr, start, end) => {
        if(pr < start || pr > end) return 0

        const peak = (start + end) / 2

        if(pr <= peak) {
            return easing((pr - start) / (peak - start))
        } else {
            return easing(1 - ((pr - peak) / (end - peak)))
        }
	}
	
	const subSection = (pr, start, end) => {
		if(pr < start) return 0
		if(pr >= end) return 1

		return easing((pr - start) / (end - start))
    }


	return inView ? <Wrapper>
        <div ref={blockRef} id="ref" style={{
            willChange: 'transform', 
            position: `${scrolling && progress !== 1 ? 'fixed' : 'absolute'}`, 
            top: `${scrolling && progress !== 1 ? '0px' : progress === 1 ? 'auto' : '0px'}`, 
            bottom: `${scrolling && progress === 1 ? '0px' : 'auto'}`,
        }}>
            <div className="section" id="first" style={{width: `${100 - (easing(entering) * (100 - blockWidth))}%`}}>
                <Top fill="#38BBA8" style={{opacity: entering}} height={progress !== 0 ? Math.floor(Math.max(peakProgress(progress, 0, .5) * 700, 10)) : 10} />
				<Tagline className="tagline" fill="#38BBA8" style={{opacity: entering}} />
				<img alt="" 
					src={images[0]}
					style={{
						transform: `translateY(${100 - (subSection(progress, .6, .9) * 100)}vh)`
					}}
				/>
            </div>
            <div className="section" id="second" style={{width: `${easing(entering) * blockWidth}%`}}>
                <Top fill="yellow" style={{opacity: entering}} height={progress !== 0 ? Math.floor(Math.max(peakProgress(progress, .25, .75) * 700, 10)) : 10} />
				<Tagline className="tagline" fill="yellow" style={{opacity: entering}} />
				<img alt="" 
					src={images[1]}
					style={{
						transform: `translateY(${100 - (subSection(progress, .7, .95) * 100)}vh)`
					}}
				/>
            </div>
            {blockWidth !== 50 ?
            <div className="section" id="third" style={{width: `${easing(entering) * (blockWidth + .01)}%`}}>
                <Top fill="white" style={{opacity: entering}} height={progress !== 0 ? Math.floor(Math.max(peakProgress(progress, .5, 1) * 700, 10)) : 10} />
				<Tagline className="tagline" fill="white" style={{opacity: entering}} />
				<img alt="" 
					src={images[2]}
					style={{
						transform: `translateY(${100 - (subSection(progress, .8, 1) * 100)}vh)`
					}}
				/>
            </div> : null}
        </div>
    </Wrapper> : null
}

export default HelloMyFriend
