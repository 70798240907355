import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ScrollContext, ChapterSplash } from './Chapters'
import BezierEasing from 'bezier-easing'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import vars from '../helpers/vars'


const CodeChar = styled.span`
	color: ${vars.colors.primary};
`

const OperatorChar = styled.span`
	color: ${vars.colors.alternative};
`

const NumberChar = styled.span`
	color: #AAA;
`

const OtherChar = styled.span`
	font-weight: bold;
	color: #666;
`

const stringParse = str => {
	str = str.split("")
	let key = 0
	str = str.map(char => {
		key++ 
		switch(char) {
			case "{" :
			case "}" :
			case "[" :
			case "]" :
			case "<" :
			case ">" :
				return <CodeChar key={key}>{char}</CodeChar>
			case "+" :
			case "-" :
			case "." :
			case "/" :
			case "*" :
			case "\"" :
				return <OperatorChar key={key}>{char}</OperatorChar>
			case "0" :
			case "1" :
			case "2" :
			case "3" :
			case "4" :
			case "5" :
			case "6" :
			case "7" :
			case "8" :
			case "9" :
				return <NumberChar key={key}>{char}</NumberChar>
			case ":" :
			case ";" :
			case "_" :
			case "=" :
				return <OtherChar key={key}>{char}</OtherChar>
			default :
				return char
		}

		
	})
	return str
}

const options = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => {
			const newChildren = children.map(child => {
				if(typeof(child) === 'string') {
					child = stringParse(child)
				}
				
				return child
			})

			return <p>{newChildren}</p>
		}
	}
}

const Wrapper = styled.div`
	height: 100%;
    width: 100vw;
	display: flex;
	justify-content: space-around;

	div#ref {
        width: 100%;
        height: 100vh;
		display: flex;
		z-index: 1;
		align-items: center;
    }
`

const FilmChapterOverlay = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: #000;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 0;
`

const ChapterTech = props => {
    const { scrolling, progress, screensInWrapper, setScreens, inView, overrideWrapHeight, setOverrideWrapHeight, goingDown } = useContext(ScrollContext)
	const easing = BezierEasing(0.11, 0, 0.5, 0)
	const blockRef = useRef(undefined)
	const [ blockHeight, setBlockHeight ] = useState(0)
	const [ body, setBody ] = useState(props.fields.body)
	const [ title, setTitle ] = useState(props.fields.title)
	const [ subTitle, setSubTitle ] = useState(props.fields.tagline)

	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
	})

	const unScramble = useCallback((str, original, intensity) => {
		intensity = BezierEasing(0.11, 0, 0.5, 0)(intensity)
		if(intensity === 1) return original
		for(let i = 0; i < intensity * 10; i++) {
			const randomIndex = Math.floor(Math.random() * str.length)
			const originalChar = original.charAt(randomIndex)
			str = str.substr(0, randomIndex) + originalChar + str.substr(randomIndex + 1)
		}

		return str
	}, [])

	const scramble = useCallback((str, progress=false) => {
		const limit = progress !== false ? (1 - progress) * (str.length / 3) : str.length
		for(let i = 0; i < limit; i++) {
			if(Math.random() < 0.1 || progress === false) {
				const chars = "{{}{{}{}{}[][][][];;;...___}}[[[<<<<<\"\"\"\"\">>>>>];;]];;;; =+*===!!??++--//***aaab bcdeeeffghi ijklmmnnoopqrst uuvwxyz1234567890.....ABCDEFGHIJKLMNOPQRSTUVWXYZ"
				const randomIndex = Math.floor(Math.random() * str.length)
				const randomChar = chars.charAt(Math.floor(Math.random() * chars.length))
				str = str.substr(0, randomIndex - 1) + randomChar + str.substr(randomIndex)
			}
		}
		return str
	}, [])

	// just once, scramble everything
	useEffect(() => {
		setBody(_b => {
			let newContent = _b

			newContent.content.forEach(item => {
				item.content.forEach(subItem => {
					if(subItem.original === undefined) {
						subItem.original = subItem.value
					}
					subItem.value = scramble(subItem.value)
					return subItem
				})
				return item
			})

			return _b
		})
		setTitle(scramble(props.fields.title))
		setSubTitle(scramble(props.fields.tagline))
	}, [props.fields.tagline, props.fields.title, scramble])

	
	useEffect(() => {
		setTitle(!goingDown ? t => scramble(t, progress) : t => unScramble(t, props.fields.title, progress))
		setSubTitle(!goingDown ? sT => scramble(sT, progress) : sT => unScramble(sT, props.fields.tagline, progress))

		setBody(_b => {
			let newContent = _b

			newContent.content.forEach(item => {
				item.content.forEach(subItem => {
					if(subItem.original === undefined) {
						subItem.original = subItem.value
					}
					subItem.value = !goingDown ? scramble(subItem.value, progress) : unScramble(subItem.value, subItem.original, progress)
					return subItem
				})
				return item
			})

			return _b
		})

	}, [progress, goingDown, props.fields.tagline, props.fields.title, scramble, unScramble])
	

	useEffect(() => {
		if(blockRef.current && blockRef.current.clientHeight !== blockHeight) {
            setBlockHeight(blockRef.current.clientHeight)
            if(!overrideWrapHeight) {
				setOverrideWrapHeight(blockRef.current.clientHeight)
			}
        }
	}, [easing, progress, blockHeight, overrideWrapHeight, setOverrideWrapHeight])

	const subSection = (pr, start, end) => {
		if(pr < start) return 0
		if(pr >= end) return 1

		return easing((pr - start) / (end - start))
	}


	return inView ? <Wrapper>
		<div className="optimal" id="ref" ref={blockRef} style={{
			willChange: 'transform',
			position: `${scrolling && progress !== 1 ? 'fixed' : 'absolute'}`, 
			top: `${scrolling && progress !== 1 ? '0px' : progress === 1 ? 'auto' : '0px'}`, 
			bottom: `${scrolling && progress === 1 ? '0px' : 'auto'}`
		}}>
			<ChapterSplash className="no-bottom-padding">
				<h2>{stringParse(title)}</h2>
				<h3>{stringParse(subTitle)}</h3>
				{documentToReactComponents(body, options)}
			</ChapterSplash>
		</div>
		<FilmChapterOverlay style={{
			opacity: `${subSection(progress, .8, 1)}`
		}}
		
		/>
		</Wrapper> : null
}

export default ChapterTech