import React, { useContext, useState } from 'react'
import { ContentContext } from '../context/ContentContext'
import styled from 'styled-components'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { Chunk, SectionHeader } from './Home'
import 'swiper/swiper.scss'

SwiperCore.use([Autoplay])

const Wrap = styled.div`
    width: 100%;
    padding: 0px;

    div.swiper-slide {
        width: auto;
        max-width: 10%;

        transform: translateX(calc(5vw + (100vw - 1600px) / 2));

        @media all and (max-width: 1600px) {
            transform: translateX(5vw);
        }

		@media all and (max-width: 1200px) {
			max-width: 13%;
		}

		@media all and (max-width: 1000px) {
			max-width: 17%;
		}

		@media all and (max-width: 600px) {
			max-width: 50%;
		}

        &.square {
            width: 5%;
        }
    }

    div.swiper-wrapper {
        display: flex;
        align-items: center;
    }
`


const Logo = styled.img`
    opacity: 1;
    height: 70px;
    width: auto;
    max-width: 100%;
    transition: opacity .3s;
    transition-delay: .35s;


    &.prev {
        opacity: .3;
    }

    &.square {
        height: 200px;
    }
`

const NameDropping = props => {
    const { content } = useContext(ContentContext)
    const [ playing, setPlaying ] = useState(true)
    const [ swiper, setSwiper ] = useState(false)
    const autoplay = true

    const isSquare = file => {
        return '' // file.details.image.width / file.details.image.height < 1.75 ? 'square' : ''
    }

    const setAutoplay = (play) => {
        if(swiper) {
            setPlaying(play)
           if(play) {
               swiper.autoplay.start()
           } else {
               swiper.autoplay.stop()
           }
        }
    }


    return (
        <React.Fragment>
            <div className="optimal">
                <Chunk className="alt">
    <SectionHeader className="name-dropping">Name-dropping {playing ? <PauseCircleOutlineIcon onClick={() => setAutoplay(false)} /> : <PlayCircleFilledIcon onClick={() => setAutoplay(true)} />}</SectionHeader>
                </Chunk>
            </div>
            <Chunk id="name-chunk">
                <Wrap>
                    {content !== false ?
                    <Swiper
                        onSwiper={(swiper) => setSwiper(swiper)}
                        spaceBetween={120}
                        slidesPerView={'auto'}
                        loop={true}
                        watchSlidesVisibility={true}
                        autoplay={autoplay ? {
                            delay: 2100,
                            disableOnInteraction: false
                        } : false}
                        speed={650}
                    >
                    {content.fields.referenceCarousel.map((name, i) => <SwiperSlide key={i} className={isSquare(name.fields.logo.fields.file)}>
                        {({isPrev}) =>
                            <Logo alt={name.name} src={name.fields.logo.fields.file.url} className={`${isPrev ? 'prev' : ''} ${isSquare(name.fields.logo.fields.file)}`} />
                        }</SwiperSlide>)}
                    </Swiper>
                    : null}
                </Wrap>
            </Chunk>
    </React.Fragment>)
}

export default NameDropping