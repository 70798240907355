import React, { createContext, useState } from 'react'
import vars from '../helpers/vars'

export const ThemeContext = createContext({})

const ThemeContextProvider = (props) => {
	const [ isDarkTheme, setTheme ] = useState(true)
	const [ colors, setColors ] = useState({
		background: vars.colors.dark,
		backgroundAlt: vars.colors.subtle,
		content: vars.colors.white
	})

	const toggle = () => {
		setTheme(!isDarkTheme)
		if(isDarkTheme) {
			setColors({
				background: vars.colors.dark,
				backgroundAlt: vars.colors.subtle,
				content: vars.colors.white
			})
		} else {
			setColors({
				background: vars.colors.white,
				backgroundAlt: vars.colors.subtle,
				content: vars.colors.dark
			})
		}
	}

	return <ThemeContext.Provider value={{toggle, colors}}>
		{props.children}
	</ThemeContext.Provider>
}

export default ThemeContextProvider