import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import vars from '../helpers/vars'
import classNames from 'classnames'
import { ContentContext } from '../context/ContentContext'
import { useLocation } from 'react-router-dom'


const BlockWrapper = styled.div``

const BorderBlink = keyframes`
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 130% 0%;
	}
`

const Wrapper = styled.div`
	width: ${props => props.size}px;
	min-height: 100vh;
	position: absolute;
	top: -${props => props.offset}px;
	left: -${props => props.offset}px;
	z-index: -1;
	background-color: ${vars.colors.dark};
	display: flex;
	flex-wrap: wrap;

	${BlockWrapper} {
		background-color: ${vars.colors.subtle};
		box-sizing: border-box;
		padding: 6px;

		&.tl {
			border-top-left-radius: ${props => props.blockSize / 2}px;
			div.inner {
				border-top-left-radius: ${props => (props.blockSize / 2) - 2}px;
			}
		}

		&.tr {
			border-top-right-radius: ${props => props.blockSize / 2}px;
			div.inner {
				border-top-right-radius: ${props => (props.blockSize / 2) - 2}px;
			}
		}

		&.bl {
			border-bottom-left-radius: ${props => props.blockSize / 2}px;
			div.inner {
				border-bottom-left-radius: ${props => (props.blockSize / 2) - 2}px;
			}
		}

		&.br {
			border-bottom-right-radius: ${props => props.blockSize / 2}px;
			div.inner {
				border-bottom-right-radius: ${props => (props.blockSize / 2) - 2}px;
			}
		}

		div.inner {
			width: 100%;
			height: 100%;
			background-color: ${vars.colors.subtle};
		}
	}
	/*
	&.animate-borders ${BlockWrapper} {
		&.funkyBorder {
			background: -moz-linear-gradient(45deg,  ${vars.colors.subtle} 46%, rgba(142,158,206,1) 66%, ${vars.colors.subtle} 86%); 
			background: -webkit-linear-gradient(45deg,  ${vars.colors.subtle} 46%,rgba(142,158,206,1) 66%,${vars.colors.subtle} 86%); 
			background: linear-gradient(45deg,  ${vars.colors.subtle} 46%,rgba(142,158,206,1) 66%, ${vars.colors.subtle} 86%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29252e', endColorstr='#29252e',GradientType=1 ); 
			background-size: 700% 100%;
		
			animation-name: ${BorderBlink};
			animation-duration: 9s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: 1;

			&.delay2 {
				animation-delay: 2s;
			}

			&.delay4 {
				animation-delay: 4s;
			}

			&.delay6 {
				animation-delay: 6s;
			}

			&.delay8 {
				animation-delay: 8s;
			}

			&.delay10 {
				animation-delay: 10s;
			}

			&.delay12 {
				animation-delay: 12s;
			}

			&.delay14 {
				animation-delay: 14s;
			}

			&.delay16 {
				animation-delay: 16s;
			}

			&.delay18 {
				animation-delay: 18s;
			}

			&.delay20 {
				animation-delay: 20s;
			}
		}  
	}*/

`


const Block = (props) => {
	const [ corners ] = useState({...props.corners})
	const [ funkyBorder, setFunkyBorder ] = useState(false)
	const [ delay, setDelay ] = useState('')
	
	const classes = classNames({
		tl: corners.tl,
		tr: corners.tr,
		bl: corners.bl,
		br: corners.br,
		funkyBorder, 
	}, delay)

	useEffect(() => {
		setFunkyBorder(Math.random() > 1 - vars.funkyBorderChance)
		setDelay('delay' + (Math.ceil(Math.random() * 10) * 2))
	}, [props.seed])

	
	return <BlockWrapper className={classes} size={props.size} style={{width: `${props.size}px`, height: `${props.size}px`}}><div className="inner" /></BlockWrapper>
}

const Gradient = styled.div`
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+100&0.65+1,0+100 */
	background: -moz-linear-gradient(top,  rgba(0,0,0,0.65) 1%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0.65) 1%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 1%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
	width: 100%;
	height: 10vh;
	position: fixed;
	top: 0px;
	z-index: 999;
`

const Background = () => {
	const [ blocks, setBlocks ] = useState([])
	const [ blocksShouldUpdate, setShouldUpdate ] = useState(false)
	const [ viewportWidth, setViewport ] = useState(Math.max(document.documentElement.clientWidth, window.innerWidth || 0))
	const [ documentHeight, setDocumentHeight ] = useState(document.body.scrollHeight)
	const [ sizes, setSizes ] = useState({
		blocksPerRow: 0,
		blockSize: 0,
		rows: 0,
		wrapperSize: 0,
		wrapperOffset: 0
	})
	const [ resizing, setResizing ] = useState(null)
	//const [ seedInterval, setSeedInterval ] = useState(null)
	const [ seed, /*setSeed*/ ] = useState(Math.random())
	//const [ scrollTimeout, setScrollTimeout ] = useState(false)
	const [ idle, /*setIdle*/ ] = useState(false)
	const { content } = useContext(ContentContext)
	const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
	const loc = useLocation()

	/*
	useEffect(() => {
		setScrollTimeout(setTimeout(() => {
			setIdle(true)
		}, 3000))
	}, [])

	useScrollPosition(() => {
		setIdle(false)

		if(scrollTimeout !== false) {
			clearTimeout(scrollTimeout)
		}

		setScrollTimeout(setTimeout(() => {
			setIdle(true)
		}, 5000))
	})

	useEffect(() => {
		if(!seedInterval && idle) {
			setSeedInterval(setInterval(() => {
				setSeed(Math.random())
			}, 20000))
		} 
	}, [seedInterval, seed, idle]) */


	useEffect(() => {
		if(documentHeight !== document.body.scrollHeight) {
			setDocumentHeight(document.body.scrollHeight)
			setShouldUpdate(true)
		}
	}, [documentHeight, content, loc])


	useEffect(() => {
		//if(blocks.length === 0) {
		if(blocksShouldUpdate) {
			setShouldUpdate(false)
			setTimeout(() => { // This timeout helps the initial render, otherwise the doc height isn't detected properly
				const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
				const heightToFill = Math.max(vh, document.body.scrollHeight)
				
				// Aim for between 4 and 8 blocks per row.
				const blocksPerRow = 6
				const blockSize = Math.round(vw / blocksPerRow)

				// Number of rows
				const rows = Math.ceil(heightToFill / blockSize) + 1
				let blockLimit = rows * (blocksPerRow + 1)
				let _blocks = [...blocks]

				if(blocks.length > blockLimit) {
					_blocks.splice(blockLimit)
				} else {
					while(_blocks.length < blockLimit) {
						_blocks.push({
							corners: {
								tl: Boolean(Math.round(Math.random())),
								tr: Boolean(Math.round(Math.random())),
								bl: Boolean(Math.round(Math.random())),
								br: Boolean(Math.round(Math.random()))
							},
							hasFunkyBorder: Math.random() < vars.background.borderChance,
							seed: Math.random()
						})
					}
				}
				
				setBlocks(_blocks)
				setSizes({
					blocksPerRow,
					blockSize,
					rows,
					wrapperSize: (blocksPerRow + 1) * blockSize,
					wrapperOffset: blockSize / 2
				})
				
			}, 1) 
			
		}
	}, [blocks.length, documentHeight, vh, blocksShouldUpdate, blocks])

	useLayoutEffect(() => {
		const resizeListener = () => {
			clearTimeout(resizing)
			setResizing(setTimeout(() => {
				setViewport(Math.max(document.documentElement.clientWidth, window.innerWidth || 0))
				setShouldUpdate(true)
			}, 1000))
		  };
		  // set resize listener
		  window.addEventListener('resize', resizeListener);
	  
		  // clean up function
		  return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener);
		  }
	}, [resizing, viewportWidth])


	return (<React.Fragment>
		<Gradient />
		<Wrapper size={sizes.wrapperSize} offset={sizes.wrapperOffset} blockSize={sizes.blockSize} className={idle ? 'animate-borders' : ''}>
			{blocks.map((block, key) => <Block key={key} corners={block.corners} size={sizes.blockSize} hasFunkyBorder={block.hasFunkyBorder} seed={seed}></Block>)}
		</Wrapper>
	</React.Fragment>)
}

export default Background