import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import vars from '../helpers/vars'
import { ContentContext } from '../context/ContentContext'
import { ThemeContext } from '../context/ThemeContext'

const Spin = keyframes`
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 0%;
	}
	100% {
		background-position: 0% 0%;
	}
`

const LoadingOverlay = styled.div`
	width: 100%;
	height: 100%;
	
	position: fixed;
	z-index: 99;
	display: flex;
	justify-content: space-around;
	align-items: center;

	div.circle {
		width: 100px;
		height: 100px;
		border-radius: 100px;
		box-sizing: border-box;
		padding: 6px;

		background: -moz-linear-gradient(45deg,  ${vars.colors.dark} 25%, rgba(142,158,206,1) 50%, ${vars.colors.dark} 75%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg,  ${vars.colors.dark} 25%,rgba(142,158,206,1) 50%,${vars.colors.dark} 75%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg,  ${vars.colors.subdarktle} 25%,rgba(142,158,206,1) 50%, ${vars.colors.dark} 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29252e', endColorstr='#29252e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		background-size: 500% 100%;
	
		animation-name: ${Spin};
		animation-duration: 1s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}

	div.inner {
		width: 100%;
		height: 100%;
		background-color: ${vars.colors.dark};
		border-radius: 100px;
	}
`

const Loading = () => {
	const { content } = useContext(ContentContext)
	const { colors } = useContext(ThemeContext)

	if(!content) {
		return <LoadingOverlay colors={colors}>
			<div className="circle">
				<div className="inner" />
			</div>
		</LoadingOverlay>
	} else {
		return null;
	}
}

export default Loading