import React, { useState, useRef, createContext, useEffect } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import styled from 'styled-components'

export const ScrollContext = createContext()

export const ChapterSplash = styled.div`
	max-width: 75%;
	padding-bottom: 150px;

	h2 {
		font-size: 200%;
		margin-bottom: 0px;
	}

	h3 {
		margin-top: 0px;
		font-family: nimbus-sans, sans-serif;
		font-weight: 200;
		font-size: 160%;
	}

	p {

	}

	@media all and (max-width: 1200px) {
		max-width: 100%;
	}

	&.no-bottom-padding {
		padding-bottom: 0px;
	}
`

const ScrollContextProvider = props => {
	

	const [ inView, setInView ] = useState(false)
	const [ scrolling, setScrolling ] = useState(true)
	const [ progress, setProgress ] = useState(0)
	const [ entering, setEntering ] = useState(0)
	const [ screensInWrapper, setScreens ] = useState(false)
	const [ wrapHeight, setWrapHeight ] = useState(false)
	const [ leaving, setLeaving ] = useState(false)
	const [ topDistance, setTopDistance ] = useState(0)
	const [ overrideWrapHeight, setOverrideWrapHeight ] = useState(false)
	const [ goingDown, setGoingDown ] = useState(true)
	const [ prevPos, setPrevPos ] = useState({x: 0, y: 0})
	const elementRef = useRef()

	// Element scroll position
	useScrollPosition(({ currPos }) => {
		const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
		const curr = Math.abs(currPos.y)
		const end = overrideWrapHeight ? wrapHeight - overrideWrapHeight : wrapHeight
		setTopDistance(curr)
		setInView(currPos.y - vh < 0 && currPos.y > -wrapHeight)

		/* Log which item is in view 
		if(currPos.y - vh < 0 && currPos.y > -wrapHeight) {
			console.log(`${props.contextId} in view`)
		} */

		setScrolling(currPos.y < 0 && currPos.y > -wrapHeight)
		setLeaving(curr + vh > wrapHeight)
		setGoingDown(prevPos.y > currPos.y)

		/* // Log big jumps in scrolling 
		if(Math.abs(prevPos.y - currPos.y) > 50) {
			console.log(`Big jump, from ${prevPos.y} to ${currPos.y} (contextID: ${props.contextId})`)
		}*/
		
		setPrevPos(currPos)
		if(scrolling && inView) {
			setProgress(Math.min(curr/end, 1))
			setEntering(1)
		} else {
			setEntering(Math.min(1 - currPos.y / vh, 1)) // USAGE UNKNOWN
			setProgress(Math.round(progress)) // Rounds up to 1 if leaving, down to 0 if entering
		}

	}, [wrapHeight, scrolling, overrideWrapHeight, entering, leaving, inView, prevPos], elementRef)

	useEffect(() => {
		setWrapHeight(screensInWrapper * Math.max(document.documentElement.clientHeight, window.innerHeight || 0))
	}, [screensInWrapper])

	return(
		<ScrollContext.Provider value={{inView, scrolling, progress, entering, setScreens, screensInWrapper, leaving, topDistance, setOverrideWrapHeight, goingDown}}>
		<div ref={elementRef} style={{height: wrapHeight + 'px', position: 'relative', overflow: props.overflowHidden === true ? 'hidden' : 'visible'}}>
			{props.children}
		</div>
		</ScrollContext.Provider>
	)
}

const ChapterWrapper = props => {
	return (<ScrollContextProvider {...props}>
			{props.children}
	</ScrollContextProvider>)
}

/*
export const ChapterOne = (props) => {
	const { scrolling, progress, inView, entering, screensInWrapper, setScreens, leaving, topDistance } = useContext(ScrollContext)
	const [ left, setLeft ] = useState(0)
	const easing = BezierEasing(0.87, 0, 0.13, 1)

	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
	})

	useEffect(() => {
		setLeft(easing(progress) * 1200)
	}, [easing, progress])


	return <div style={{transform: `translateX(${left}px)`, backgroundColor: '#000', border: '1px solid #fff', width: '200px', height: '100%'}}>
		<div>Hello 1</div>
	</div>
}

export const ChapterTwo = props => {
	const { scrolling, progress, inView, entering, screensInWrapper, setScreens, leaving, topDistance, setOverrideWrapHeight, overrideWrapHeight, setLog } = useContext(ScrollContext)
	const easing = BezierEasing(0.87, 0, 0.13, 1)
	const [ blockHeight, setBlockHeight ] = useState(0)
	const [ inverseEaseProgress, setInverseEaseProgress ] = useState(progress)
	const blockRef = useRef(undefined)

	useEffect(() => {
		setLog(true)
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
	})


	useEffect(() => {
		setInverseEaseProgress(easing(1 - progress))
		if(blockRef.current && blockRef.current.clientHeight !== blockHeight) {
			setBlockHeight(blockRef.current.clientHeight)
			if(!overrideWrapHeight) {
				setOverrideWrapHeight(blockRef.current.clientHeight)
			}
		}
	}, [progress])


	return (
		inView ? <div ref={blockRef} 
		style={{
			willChange: 'transform', 
			position: `${scrolling && progress !== 1 ? 'fixed' : 'absolute'}`, 
			top: `${scrolling && progress !== 1 ? '0px' : 'auto'}`, 
			bottom: `${scrolling && progress === 1 ? '0px' : 'auto'}`,
			padding: '50px', 
			width: '100%',
			height: '100vh',
			display: 'flex',
			boxSizing: 'border-box',
			justifyContent: 'space-around',
			alignItems: 'center',
			perspective: '500px',
			transformStyle: 'preserve-3d'
		}}>
			<div style={{
				perspective: '500px',
				transformStyle: 'preserve-3d',
				display: 'flex',
				transform: `translate3d(0, 0, ${100 * inverseEaseProgress}px) rotate3d(1, 1, 2, ${25 * inverseEaseProgress}deg)`
			}}>
			<span style={{
				transform: `translateZ(${320 * inverseEaseProgress}px)`,
				filter: `blur(${inverseEaseProgress * 3.2}px)`
			}}>Tell me,&nbsp;</span>
			<span style={{
				transform: `translateZ(${120 * inverseEaseProgress}px)`,
				filter: `blur(${inverseEaseProgress * 1.2}px)`
			}}>what&nbsp;</span>
			<span style={{
				transform: `translateZ(${220 * inverseEaseProgress}px)`,
				filter: `blur(${inverseEaseProgress * 2.2}px)`
			}}>do&nbsp;</span>
			<span style={{
				transform: `translateZ(${260 * inverseEaseProgress}px)`,
				filter: `blur(${inverseEaseProgress * 2.6}px)`
			}}>you&nbsp;</span>
			<span style={{
				transform: `translateZ(${380 * inverseEaseProgress}px)`,
				filter: `blur(${inverseEaseProgress * 3.8}px)`
			}}>know?</span>
			</div>
		</div> : null
	)
}

export const ChapterThree = props => {
	const { scrolling, progress, inView, entering, screensInWrapper, setScreens, leaving, topDistance } = useContext(ScrollContext)
	const [ rotation, setRotation ] = useState(0)
	const [ imageOpacity, setImageOpacity ] = useState(0)
	const initialBlur = 50
	const [ blur, setBlur ] = useState(initialBlur)
	const easing = BezierEasing(0.65, 0, 0.35, 1)

	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
	})

	useEffect(() => {
		setRotation(easing(progress) * 720)
		setImageOpacity(easing(progress) * 2)
		setBlur(initialBlur - (easing(progress * 2) * initialBlur))
	}, [easing, progress])

	return (<div style={{overflow: 'hidden', height: '100%'}}>
		<div style={{backgroundImage: `url(${space})`, width: '100%', height: '100%', willChange: 'transform',  opacity: imageOpacity, filter: `blur(${blur}px)`}} />
	</div>)
}

const FourWrap = styled.div`
	height: 100vh;
	padding: 50px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: '#FF0000';
`

export const ChapterFour = props => {
	const { scrolling, progress, inView, entering, screensInWrapper, setScreens, leaving, topDistance, setOverrideWrapHeight, overrideWrapHeight } = useContext(ScrollContext)
	const fullSentence = ", how is your day going? I am fine, thanks for asking."
	const [ content, setContent ] = useState('')
	const blockRef = useRef(undefined)
	const [ blockHeight, setBlockHeight ] = useState(0)

	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
	})

	useEffect(() => {
		const strLen = Math.floor(fullSentence.length * progress)
		setContent(fullSentence.substr(0, strLen))
		if(blockRef.current && blockRef.current.clientHeight !== blockHeight) {
			setBlockHeight(blockRef.current.clientHeight)
			if(!overrideWrapHeight) {
				setOverrideWrapHeight(blockRef.current.clientHeight)
			}
		}
	}, [progress])

	return inView ? <FourWrap 
		ref={blockRef} 
		style={{
			willChange: 'transform', 
			top: `${scrolling && progress !== 1 ? '0px' : 'auto'}`, 
			bottom: `${scrolling && progress === 1 ? '0px' : 'auto'}`,
			position: `${scrolling && progress !== 1 ? 'fixed' : 'absolute'}`, 
		}}>
		Hello..{content}</FourWrap> : null
}

export const GalleryThree = props => {
	const { scrolling, progress, inView, entering, screensInWrapper, setScreens, leaving, topDistance } = useContext(ScrollContext)

	return inView ? <div>Gallery</div> : null
}
*/

export default ChapterWrapper