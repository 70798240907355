import React from 'react'
import styled from 'styled-components'
import vars from '../../helpers/vars'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import renderOptions from '../../helpers/renderOptions'


const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
	max-width: calc(${vars.maxWidth} + 20%);
	padding: ${vars.defaultPadding.y} ${vars.defaultPadding.x};
	margin: 0px auto 150px auto;
`
const Image = styled.img`
	width: 50%;
`

const Text = styled.div`
	width: 40%;
`

const BlockMediaWText = props => {

	return <Wrapper>
		<Text>{documentToReactComponents(props.fields.text, renderOptions)}</Text>
		<Image src={`${props.fields.media.fields.file.url}?w=1000&h=1000`} alt={props.fields.media.fields.description} title={props.fields.media.fields.description} />
	</Wrapper>
}

export default BlockMediaWText