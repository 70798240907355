import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollContext } from '../Chapters'
import { LayoutContext } from '../../context/LayoutContext'
import styled from 'styled-components'
import BezierEasing from 'bezier-easing'
import PVMGradient from '../../assets/pvm/pvm-gradient.jpg'
import PVMOutline from '../../assets/pvm/pvm-outline.svg'
import PVMLogo from '../../assets/pvm/pvm-full.png'

const Wrap = styled.div`
	height: 100%;
	width: 100vw;
	position: relative;
	display: flex;

	div#ref {
		width: 100%;
        height: 100vh;
		overflow: hidden;
		background-color: #FFF;

		div.square-wrap {
			perspective: 500px;
			width: 100%;
			height: 100%;
			transform-style: preserve-3d;
			display: flex;
			align-items: center;
			justify-content: space-around;
			z-index: 5;
			position: relative;
		}

		div.initial-logo {
			position: absolute;
			width: 100vw;
			height: 100vh;
			z-index: 6;
			top: 0px;
			left: 0px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			perspective: 500px;
			transform-style: preserve-3d;

			img {
				
			}
		}
	}
`

const Square = styled.div`
	position: relative;
`

const Background = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0px;
	left: 0px;
`

const Gradient = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0px;
	left: 0px;
`

const Outline = styled.img`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
`

const PVM = props => {
	const { scrolling, progress, entering, screensInWrapper, setScreens, overrideWrapHeight, setOverrideWrapHeight } = useContext(ScrollContext)
	const { viewportWidth, viewportHeight } = useContext(LayoutContext)
	const backgroundImage = props.media.find(el => el.sys.id === props.specifics.pvm.backgroundId)
	const blockRef = useRef(undefined)
	const [ blockHeight, setBlockHeight ] = useState(0)
	const [ viewport, setViewport ] = useState(false)
	const easing = BezierEasing(0.85, 0, 0.15, 1)

	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
	})

	useEffect(() => {
		//if(!viewport) {
			setViewport({
				w: viewportWidth,
				h: viewportHeight,
				squareHeight: Math.min(viewportWidth, viewportHeight) * .25, // square = 25% of height or width (whichever is smallest)
				logoWidth: (Math.min(viewportWidth, viewportHeight) * .25) / 0.0543 // square is 5,43% of total logo width
			})
		//}
	}, [viewportHeight, viewportWidth])

	const subSection = (pr, start, end) => {
		if(pr < start) return 0
		if(pr >= end) return 1

		return easing((pr - start) / (end - start))
	}

	useEffect(() => {
		if(blockRef.current && blockRef.current.clientHeight !== blockHeight) {
            setBlockHeight(blockRef.current.clientHeight)
            if(!overrideWrapHeight) {
				setOverrideWrapHeight(blockRef.current.clientHeight)
			}
        }
	}, [blockHeight, overrideWrapHeight, setOverrideWrapHeight])


	const getSquareWidth = () => {
		return viewport.w - ((viewport.w - viewport.squareHeight) * easing(progress))
	}

	const getSquareHeight = () => {
		return viewport.h - ((viewport.h - viewport.squareHeight) * easing(progress))
	}

	return <Wrap>
			<div id="ref" ref={blockRef} style={{
				position: `${scrolling && progress !== 1 ? 'fixed' : 'absolute'}`, 
				top: `${scrolling && progress !== 1 ? '0px' : progress === 1 ? 'auto' : '0px'}`, 
				bottom: `${scrolling && progress === 1 ? '0px' : 'auto'}`,
				opacity: `${entering}`
			}}>
				<div className="square-wrap">
					<Square style={{
						transform: `translateZ(${200 - (easing(progress) * 200)}px)`,
						width: `${getSquareWidth()}px`,
						height: `${getSquareHeight()}px`
					}}>
					<Background src={backgroundImage.fields.file.url} alt="" style={{
						borderRadius: `${0 + (easing(progress) * (viewport.squareHeight * .23))}px`,
						boxShadow: `0px ${50 - (easing(progress) * 50)}px ${120 - (easing(progress) * 80)}px rgba(0, 0, 0, .7)`,
						transform: `translate3d(0, 0, ${100 * easing(progress)}px)`,
						filter: `grayscale(${easing(progress) * 100}%)`,
						opacity: `${1 - subSection(progress, .4, 1)}`,
						zIndex: 3
					}}/>
					<Gradient src={PVMGradient} alt="" style={{
						borderRadius: `${0 + (easing(progress) * (viewport.squareHeight * .23))}px`,
						zIndex: 2
					}} />
					</Square>
				</div>
				<div className="initial-logo">
					<img src={PVMLogo} alt="" style={{
						height: `${viewport.squareHeight * .67}px`,
						transform: `translateZ(${easing(progress) * 225}px)`,
						filter: `blur(${subSection(progress, 0.2, 0.8) * 15}px)`,
						opacity: `${1 - subSection(progress, 0.2, 0.8)}`
					}} />
				</div>
				<Outline src={PVMOutline} alt="" style={{
					width: `${viewport.logoWidth}px`,
					transform: `translateX(${-((viewport.logoWidth * .64) - (viewport.w / 2))}px) translateY(${-((viewport.logoWidth * .376 * .394) - (viewport.h / 2))}px)`,
					opacity: `${subSection(progress, 0.8, 1)}`,
					zIndex: 1
				}}/>
				
			</div>
		</Wrap>
}

export default PVM