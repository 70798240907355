import React, { useContext } from 'react'
import { ContentContext } from '../context/ContentContext'
import { SectionHeader } from './Home'
import { ChapterSplash } from './Chapters'
import styled from 'styled-components'
import vars from '../helpers/vars'
import TeamMember from './TeamMember'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Vimeo from '@u-wave/react-vimeo'
import renderOptions from '../helpers/renderOptions'



const AboutWrap = styled.div`
    // no additiona styles yet

    .touchIcon {
        display: none;
    }

    @media (hover: none) {
        .touchIcon {
            display: inline-block;
        }
    }
`

const Orange = styled.div`
    color: ${vars.colors.primary};
    padding-top: 1.5em;

    h4 {
        font-family: nimbus-sans, sans-serif;
        font-weight: 1000;
    }
`

const Team = styled.div`

    div.main {
        color: ${vars.colors.green};

        h2 {
            color: ${vars.colors.green};
        }
    }

    div.external {
        color: ${vars.colors.tertiary};

        a {
            color: ${vars.colors.tertiary};
        }

        h2 {
            color: ${vars.colors.tertiary};
        }
    }

	
    div.main > div, div.external > div {
        display: flex;
        flex-wrap: wrap;
		justify-content: space-between;
    }
`

const OfficeWrapper = styled.div`
    width: 100vw;
    height: auto;
    margin-top: 10vh;
	position: relative;
	min-height: 100vh;

	iframe {
		width: 100vw;
		height: 56.25vw; // 16:9 ratio
		min-height: 100vh;
		min-width: 177.77vh; // 16:9
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
	}
`



const About = props => {
	const { content } = useContext(ContentContext)
    

    return content.fields ? <AboutWrap>
        <div className="optimal">
            <ChapterSplash>
				<h2>{content.fields.about.fields.title}</h2>
                {documentToReactComponents(content.fields.about.fields.introduction, renderOptions)}
                <Orange>
					{documentToReactComponents(content.fields.about.fields.collab, renderOptions)}
                </Orange>
            </ChapterSplash>
        </div>
        <Team className="optimal">
            <div className="main">
                <SectionHeader>De creatieve kern <TouchAppIcon className="touchIcon" /></SectionHeader>
                <div>
                    {content.fields.team.fields.main.map((item, i) => <TeamMember key={i} {...item} initials={true} />)}
                </div>
            </div>
            <div className="external">
                <SectionHeader>Externe helden</SectionHeader>
                <div>
                    {content.fields.team.fields.external.map((item, i) => <TeamMember key={i} {...item} initials={false} />)}
                </div>
            </div>
        </Team>
        <OfficeWrapper>
			<Vimeo 
				video="515689314"
				controls={false} 
				showPortrait={false} 
				showTitle={false} 
				muted={true} 
				autoplay={true} 
				background={true} 
				showByline={false} 
				loop={true} />
		</OfficeWrapper>
    </AboutWrap> : null
}

export default About