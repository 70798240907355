import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ChapterSplash } from '../Chapters'
import renderOptions from '../../helpers/renderOptions'


const BlockText = props => {
	return <div className="optimal">
		<ChapterSplash>
			{documentToReactComponents(props.fields.text, renderOptions)}
		</ChapterSplash>
	</div>
}

export default BlockText