import React, { createContext, useState } from 'react'

export const LanguageContext = createContext({})

const LanguageContextProvider = (props) => {
	const [ locale, setLocale ] = useState({
		'nl': true,
		'en-US': false
	})

	const toggle = () => {
		setLocale({
			'nl': !locale['nl'],
			'en-US': !locale['en-US']
		})
	}

	return <LanguageContext.Provider value={{locale, toggle}}>
		{props.children}
	</LanguageContext.Provider>
}

export default LanguageContextProvider