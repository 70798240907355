
import { INLINES } from '@contentful/rich-text-types'
import React from 'react'

const renderOptions = {
	renderNode: {
		[INLINES.HYPERLINK]: (node, next) => {
			return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{next}</a>
		}
	}
}

export default renderOptions