import React from 'react'
import styled from 'styled-components'
import vars from '../../helpers/vars'

const Wrapper = styled.div`
	display: flex;
	margin-bottom: 150px;

	&.spacious {
		width: 100%;
		box-sizing: border-box;
		max-width: calc(${vars.maxWidth} + 20%);
		padding: ${vars.defaultPadding.y} ${vars.defaultPadding.x};
		margin: 0px auto 150px auto;

		justify-content: space-between;

		img {
			object-fit: cover;
			&:first-of-type {
				max-width: 50%;
			}

			&:last-of-type {
				max-width: 40%;
			}
		}
	}

	&.full {
		width: 100vw;
		height: 100vh;
		margin-bottom: 150px;

		img {
			width: 50%;
			height: 100%;
			object-fit: cover;
		}
	}
`

const Image = styled.img``

const BlockMedia2 = props => {
	const layoutOptions = props.fields.layout.split(", ").map(opt => opt.toLowerCase().split(" ").join("-"))

	return <Wrapper className={layoutOptions}>
		{props.fields.media.map((media, key) => {
			switch(media.fields.file.contentType) {
				case "image/png" :
				case "image/jpg" :
					return <Image key={key} src={`${media.fields.file.url}?w=1000&h=1000`} alt={media.fields.description} title={media.fields.description} />
				default :
				return <span>Invalid content type found in renderer</span>
			}
		})}
	</Wrapper>
}

export default BlockMedia2