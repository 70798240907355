import React, { useState, useContext, useEffect, useRef } from 'react'
import { ScrollContext } from '../Chapters'
import F from '../../assets/floralove/F.svg'
import L from '../../assets/floralove/L.svg'
import O from '../../assets/floralove/O.svg'
import R from '../../assets/floralove/R.svg'
import A from '../../assets/floralove/A.svg'
import V from '../../assets/floralove/V.svg'
import E from '../../assets/floralove/E.svg'
import Leaf1 from '../../assets/floralove/Leaf1.svg'
import Leaf2 from '../../assets/floralove/Leaf2.svg'
import Leaf3 from '../../assets/floralove/Leaf3.svg'
import Every from '../../assets/floralove/Every.svg'
import Home from '../../assets/floralove/Home.svg'
import Deserves from '../../assets/floralove/Deserves.svg'
import ATagline from '../../assets/floralove/ATagline.svg'
import Plant from '../../assets/floralove/Plant.svg'

import BezierEasing from 'bezier-easing'
import styled from 'styled-components'
import { LayoutContext } from '../../context/LayoutContext'

const FloraloveWrapper = styled.div`
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-around;

    img#bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    div.logo-wrap {
        display: flex;
        width: 90%;
        height: 100vh;
        align-items: center;
        justify-content: space-around;

        > div {
            width: 100%;
        }

        img {
            width: auto;
            margin-right: 8px;
		}
		
		div#tagline {
			width: 80%;
		}
    }

	&.small div.logo-wrap img {
		margin-right: 6px;
	}

	&.x-small div.logo-wrap img {
		margin-right: 3px;
	}
`


const Floralove = props => {
    const { scrolling, progress, entering, screensInWrapper, setScreens, overrideWrapHeight, setOverrideWrapHeight } = useContext(ScrollContext)
    const { viewportWidth } = useContext(LayoutContext)
    const blockRef = useRef(undefined)
    const [ imageOpacity, setImageOpacity ] = useState(0)
    const initialBlur = 150
    const [ blur, setBlur ] = useState(initialBlur)
    const [ blockHeight, setBlockHeight ] = useState(0)
    const easing = BezierEasing(0.65, 0, 0.35, 1)
    const [scale, setScale] = useState(.6)
	const [ scaleClass, setScaleClass ] = useState('normal')
    
    const backgroundImage = props.media.find(el => el.sys.id === props.specifics.floralove.backgroundId)

	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
    })
    
    useEffect(() => {
        if(viewportWidth > 1200) {
            setScale(0.6)
			setScaleClass('normal')
            return
        }

        if(viewportWidth > 1000) {
			setScaleClass('normal')
            setScale(0.51)
            return
        }

        if(viewportWidth > 900) {
			setScaleClass('normal')
            setScale(0.46)
            return
        }

		if(viewportWidth > 780) {
			setScaleClass('small')
            setScale(0.410)
            return
        }

        if(viewportWidth > 690) {
			setScaleClass('small')
            setScale(0.390)
            return
        }

        if(viewportWidth > 590) {
			setScaleClass('small')
            setScale(0.35)
            return
        }

        if(viewportWidth > 520) {
			setScaleClass('x-small')
            setScale(0.3)
            return
        }

		if(viewportWidth > 400) {
			setScaleClass('x-small')
            setScale(0.22)
            return
        }


		setScaleClass('x-small')

        setScale(0.17)

    }, [ viewportWidth ])

	useEffect(() => {
        const _progress = Math.min(entering, 1)
		setImageOpacity(Math.min(easing(_progress), 0.55))
        setBlur(initialBlur - (easing(_progress) * initialBlur))

        if(blockRef.current && blockRef.current.clientHeight !== blockHeight) {
            setBlockHeight(blockRef.current.clientHeight)
            if(!overrideWrapHeight) {
				setOverrideWrapHeight(blockRef.current.clientHeight)
			}
        }

	}, [easing, progress, blockHeight, entering, overrideWrapHeight, setOverrideWrapHeight])

	const subSection = (pr, start, end) => {
		if(pr < start) return 0
		if(pr >= end) return 1

		return easing((pr - start) / (end - start))
	}


	return <FloraloveWrapper className={scaleClass}>
        <img
            alt=""
            style={{
                opacity: imageOpacity,
                filter: `blur(${blur}px)`,
                transform: `translateZ(${progress * 35}px)`
            }}
            src={backgroundImage.fields.file.url} id="bg" />
            <div className="logo-wrap" 
                ref={blockRef}
                style={{
                    willChange: 'transform', 
                    position: `${scrolling && progress !== 1 ? 'fixed' : 'absolute'}`, 
                    top: `${scrolling && progress !== 1 ? '0px' : progress === 1 ? 'auto' : '0px'}`, 
                    bottom: `${scrolling && progress === 1 ? '0px' : 'auto'}`,
					paddingLeft: 0
                }}
                >
                    <div style={{width: 'auto', paddingLeft: 283 * scale}}>
                        <img style={{
                            height: `${203 * scale}px`,
                            opacity: `${(entering - .4) / 0.6}`
                            }}
                            src={F}
                            alt="" />
                        <img style={{
                            height: `${224 * scale}px`,
                            opacity: `${(entering - .5) / 0.5}`
                            }}
                            src={L}
                            alt="" />
                        <img style={{
                            height: `${165 * scale}px`,
                            transform: `translateY(${3 * scale}px) translateX(${2 * scale}px)`,
                            opacity: `${(entering - .6) / 0.4}`
                            }}
                            src={O}
                            alt="" />
                        <img style={{
                            height: `${162 * scale}px`,
                            opacity: `${(entering - .65) / 0.35}`
                            }}
                            src={R}
                            alt="" />
                        <img style={{
                            height: `${162 * scale}px`,
                            transform: `translateY(${progress * 3 * scale}px) translateX(-${progress * 3 * scale}px)`,
                            opacity: `${(entering - .7) / 0.3}`
                            }}
                            src={A}
                            alt="" />
                        <img style={{
                            height: `${224 * scale}px`,
                            transform: `translateY(-${progress * 1 * scale}px) translateX(-${progress * 8 * scale}px) rotateZ(${-progress}deg)`,
                            opacity: `${(entering - .75) / 0.25}`
                            }}
                            src={L}
                            alt="" />
                        <img style={{
                            height: `${165 * scale}px`,
                            transform: `translateY(-${progress * 9 * scale}px) translateX(-${Math.min(0.1 + progress, 1) * 9 * scale}px) rotateZ(${-progress * 5}deg)`,
                            opacity: `${(entering - .8) / 0.2}`
                            }}
                            src={O}
                            alt="" />

                        <img style={{
                            height: `${160 * scale}px`,
                            transform: `translateY(-${progress * 44 * scale}px) translateX(-${Math.min(0.9 + progress, 1) * 20 * scale}px) rotateZ(${-progress * 13}deg)`,
                            opacity: `${(entering - .85) / 0.15}`
                            }}
                            src={V}
                            alt="" />
                        <img style={{
                            height: `${163 * scale}px`,
                            transform: `translateY(-${progress * 83 * scale}px) translateX(-${Math.min(0.8 + progress, 1) * 39 * scale}px) rotateZ(${-progress * 26}deg)`,
                            opacity: `${(entering - .88) / 0.12}`
                            }}
                            src={E}
                            alt="" />
                        <img style={{
                            height: `${52 * scale}px`,
                            transform: `translateX(-${334 * scale}px) translateY(-${225 * scale}px) rotateZ(0deg)`,
                            opacity: `${(progress - .8) / 0.2}`
                            }}
                            src={Leaf1}
                            alt="" />
                        <img style={{
                            height: `${120 * scale}px`,
                            transform: `translateX(-${132 * scale}px) translateY(-${274 * scale}px) rotateZ(0deg)`,
                            opacity: `${(progress - .9) / 0.1}`
                            }}
                            src={Leaf3}
                            alt="" />
                        <img style={{
                            height: `${50 * scale}px`,
                            transform: `translateX(-${330 * scale}px) translateY(${2 * scale}px) rotateZ(0deg)`,
                            opacity: `${(progress - .85) / 0.15}`
                            }}
                            src={Leaf2}
                            alt="" />
						<div id="tagline" style={{
							marginTop: `${30 * scale}px`
						}}>
							<img style={{
									width: `${143 * scale}px`,
									transform: `translateY(${7 * scale}px) translateX(${20 - (subSection(progress, .5, .95) * 20)}px)`,
									opacity: subSection(progress, .5, .95)
								}}
								alt="" src={Every} />
							<img style={{
									width: `${136 * scale}px`,
									transform: `translateX(${20 - (subSection(progress, .55, .95) * 20)}px)`,
									opacity: subSection(progress, .55, .95)
								}}
								alt="" src={Home} />
							<img style={{
									width: `${231 * scale}px`,
									transform: `translateX(${20 - (subSection(progress, .6, .95) * 20)}px)`,
									opacity: subSection(progress, .6, .95)
								}}	
								alt="" src={Deserves} />
							<img style={{
									width: `${27 * scale}px`,
									transform: `translateX(${20 - (subSection(progress, .65, .95) * 20)}px)`,
									opacity: subSection(progress, .65, .95)
								}}
								alt="" src={ATagline} />
							<img style={{
									width: `${141 * scale}px`,
									transform: `translateY(${7 * scale}px) translateX(${20 - (subSection(progress, .7, .95) * 20)}px)`,
									opacity: subSection(progress, .7, .95)
								}}
								alt="" src={Plant} />
						</div>
                    </div>
            </div>
    </FloraloveWrapper>
}

export default Floralove
