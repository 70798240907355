import React, { useState, useLayoutEffect, useRef, useEffect, useCallback, useContext } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { NavigationContext } from "../context/NavigationContext"
import logo from '../assets/mark.svg'
import styled from 'styled-components'
import vars from '../helpers/vars'
import { SectionHeader } from  '../components/Home'
import { CSSTransition } from 'react-transition-group'
import { useHistory, useLocation } from "react-router-dom"
import { useInView } from "react-intersection-observer"

const ContactOverlay = styled.div`
	position: fixed;
	cursor: s-resize;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.8);
	backdrop-filter: blur(9px) saturate(0%);
	display: flex;
	align-items: center;

	transition: opacity .4s;

	a {
		cursor: pointer;
	}

	div.inner {
		transition: transform 2s cubic-bezier(0.16, 1, 0.3, 1), opacity 2s;
	}

	&.state-enter {
		opacity: 0;

		div.inner {
			transition-delay: .2s;
			transform: translateY(10vh);
			opacity: 0;
		}
	}

	&.state-enter-active {
		opacity: 1;

		div.inner {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&.state-exit {
		opacity: 1;
		transition-delay: .6s;

		div.inner {
			transition-delay: 0s;
			transition: transform .6s cubic-bezier(0.5, 0, 0.75, 0), opacity .6s cubic-bezier(0.5, 0, 0.75, 0);
			transform: translateY(0);
		}
	}

	&.state-exit-active {
		opacity: 0;

		div.inner {
			transform: translateY(20vh);
			opacity: 0;
		}
	}
`

const ContactButton = styled.li`
	cursor: pointer;
`

const NavWrap = styled.nav`
	font-weight: 300;
	position: absolute;
	max-width: ${vars.maxWidth};
	padding: 50px ${vars.defaultPadding.x};
	box-sizing: border-box;
	top: 0px;
	z-index: 1000;
	width: 100%;

	&.sticky {
		position: fixed;
		width: 100%;
		margin: 0px auto;
		top: -${props => props.stickyTreshold}px;

		img.mark {
			opacity: 1;
			transform: translateX(0px);
			transition-delay: .3s;
		}

		div.main ul {
			margin-left: 80px;

			@media all and (max-width: 480px) {
				margin-left: 0px;
			}
		}

		a {
			padding-bottom: 10px !important;
		}
	}

	div.brand {
		font-size: 80%;
		padding-bottom: 50px;
		display: flex;
		color: ${vars.colors.primary};
		align-items: center;
		justify-content: space-between;

		img {
			margin-right: 15px;
		}

		> span {
			display: flex;
			align-items: center;
		}

		span.pointer {
			cursor: pointer;
		}

		@media all and (max-width: 600px) {
			flex-direction: column;

			> span:first-of-type {
				margin-bottom: 25px;
			}

			span.brand {
				font-size: 170%;
			}
		}
	}

	div.main ul {
		margin-left: 0px;
		transition: margin-left .5s;
	}

	img.mark {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		transition: opacity .2s, transform .2s;
		transition-delay: 0s;
		transform: translateX(-20px);

		@media all and (max-width: 480px) {
			display: none;
		}
	}

	div.links {
		font-size: 50%;
		display: flex;
		justify-content: space-between;

		> div {
			display: flex;
			align-items: center;
		}

		ul {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			list-style-type: none;
			padding: 0px;
			margin: 0px;

			> li {
				margin: 0px;
				padding: 0px;

				:not(:last-child) {
					margin-right: 50px;

					@media all and (max-width: 700px) {
						margin-right: 25px;
					}
				}

				a, span {
					text-decoration: none;
					color: ${vars.colors.white};
					padding-bottom: 25px;
					transition: padding-bottom .5s;
					cursor: pointer;

					&.active {
						border-bottom: 1px solid white;
					}
				}
			}
		}
	}
`

const CustomNavLink = (props) => {
  const { activePath, navigate } = useContext(NavigationContext)
  const url = `${props.to}${props.hash ? "#" + props.hash : ""}`
  const isActive = activePath === url

  return (
		<span onClick={() => navigate(url)} className={isActive ? "active" : ""}>
			{props.children}
		</span>
	)
}

export const AnchorWrapper = styled.span`
  opacity: 0;
  height: 0px;
  width: 0px;
  pointer-events: none;
`

export const CustomAnchor = (props) => {
  const { setActive } = useContext(NavigationContext)
  const { pathname } = useLocation()

  const { ref, inView } = useInView({
      threshold: [1],
  })

  useEffect(() => {
    if (inView) {
      window.history.replaceState({},"",`${pathname}${props.hash ? "#" + props.hash : ""}`)
      setActive(`${pathname}${props.hash ? "#" + props.hash : ""}`)
    }
  }, [inView, pathname, props.hash, setActive])

	return (
		<AnchorWrapper ref={ref} id={props.noAnchor ? "" : props.hash}>
			{props.children}
		</AnchorWrapper>
	)
}


const Nav = () => {
	const [ isSticky, setSticky ] = useState(false)
	const [ stickyTreshold, setStickyThreshold ] = useState(0)
  	const [ resizing, setResizing ] = useState(null)
	const history = useHistory();
	const [ showContact, setShowContact ] = useState(false)
  	const handleOnClick = useCallback(() => history.push('/'), [history])
  	const navRef = useRef(null)
  	const { pathname, hash } = useLocation()

	useLayoutEffect(() => {
		const navResizeListener = () => {
			clearTimeout(resizing)
			setResizing(setTimeout(() => {
				setStickyThreshold(navRef.current.clientHeight - 90)
			}, 500))
		  };
		  // set resize listener
		  window.addEventListener('resize', navResizeListener);

		  // clean up function
		  return () => {
			// remove resize listener
			window.removeEventListener('resize', navResizeListener);
		  }
	}, [resizing])

	useEffect(() => {
		if(navRef.current !== undefined) {
			setStickyThreshold(navRef.current.clientHeight - 90)
		}
  }, [navRef])

  useEffect(() => {
		if (hash) {
      const $target = document.getElementById(hash.substring(1))
      if ($target) {
        window.scrollTo(0, $target.offsetTop - 100)
      }
		} else {
			window.scrollTo(0, 0)
		}
	}, [pathname, hash])

	useScrollPosition(({ prevPos, currPos }) => {
		setSticky(currPos.y < -stickyTreshold)
  })


	return(<React.Fragment><NavWrap ref={navRef} className={isSticky ? 'sticky' : ''} stickyTreshold={stickyTreshold}>
			<div className="brand monotalic">
				<span onClick={handleOnClick} className="pointer"><img src={logo} alt="srj.agency" /><span className="brand">srj.agency</span></span>
				<span>Creating lovable brands since 2008</span>
			</div>
			<div className="links">
				<div className="main">
					<img className="mark" src={logo} alt="srj.agency" />
					<ul>
						<li>
							<CustomNavLink to="/">Aangenaam</CustomNavLink>
						</li>
						<li>
							<CustomNavLink to="/" hash="showcase">Showcase</CustomNavLink>
						</li>
						<li>
							<CustomNavLink to="/"  hash="process">Werkwijze</CustomNavLink>
						</li>
						<li>
							<CustomNavLink to="/about">Team &amp; about</CustomNavLink>
						</li>
					</ul>
				</div>
				<ul>
					{/*<LangSwitch>Nederlands / English</LangSwitch>*/}
					<ContactButton onClick={() => setShowContact(!showContact)}>Contact</ContactButton>
				</ul>
			</div>
		</NavWrap>
		<CSSTransition
			in={showContact}
			timeout={1500}
			classNames="state"
			unmountOnExit
		>
			<ContactOverlay onClick={() => setShowContact(false)}>
				<div className="optimal primary inner">
				<SectionHeader>Contact</SectionHeader>
					<div className="text">
						<strong>srj.agency</strong><br/>
						<span>Handelskade 18</span><br/>
						<span>5211 TH 's-Hertogenbosch</span><br/>
						<span>The Netherlands</span><br/>
						<span><a href="https://goo.gl/maps/aYZMGPaVLaXijKbMA" target="_blank" rel="noopener noreferrer">Google Maps</a></span><br/><br/>
						<span>+31(0)73 851 65 92</span><br/>
						<span><a href="mailto:hi@srj.agency" target="_blank" rel="noopener noreferrer">hi@srj.agency</a></span><br/>
						<span><a href="https://api.whatsapp.com/send/?phone=31612596821&text&app_absent=0" target="_blank" rel="noopener noreferrer">WhatsApp</a></span><br/>
					</div>
				</div>
			</ContactOverlay>
		</CSSTransition>
		</React.Fragment>)
}


export default Nav