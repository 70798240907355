import React, { useContext } from 'react'
import { SectionHeader } from './Home'
import { ContentContext } from '../context/ContentContext'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import renderOptions from '../helpers/renderOptions'


const Imprint = styled.div`
	opacity: .4;
	font-size: 50%;
	padding-top: 150px;
	padding-bottom: 150px;

	p {
		display: inline;
	}

	a {
		color: #FFF !important;
	}
`

const Footer = props => {
	const thisYear = new Date().getFullYear()
	const { content } = useContext(ContentContext)

	return(content ? (
		<React.Fragment>
	<div className="optimal primary">
				<SectionHeader>Laat van je horen</SectionHeader>
				<div className="text">
					<strong>srj.agency</strong>
					<br />
					<span>Handelskade 18</span>
					<br />
					<span>5211 TH 's-Hertogenbosch</span>
					<br />
					<span>The Netherlands</span>
					<br />
					<br />
					<span>+31(0)73 851 65 92</span>
					<br />
					<span>
						<a
							href="mailto:hi@srj.agency"
							target="_blank"
							rel="noopener noreferrer"
						>
							hi@srj.agency
						</a>
					</span>
					<br />
					<span>
						<a
							href="https://api.whatsapp.com/send/?phone=31612596821&text&app_absent=0"
							target="_blank"
							rel="noopener noreferrer"
						>
							WhatsApp
						</a>
					</span>
					<br />
				</div>
			</div>

			<div className="optimal">
				<Imprint>
					Copyright &copy; srj.agency {thisYear}.{" "}
					{documentToReactComponents(
						content.fields.unsplashReference,
						renderOptions
					)}
				</Imprint>
			</div>
			</React.Fragment>
	) : null)
}

export default Footer