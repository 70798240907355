import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import renderOptions from '../helpers/renderOptions'


const Wrap = styled.div`
    width: 25vw;
    margin-bottom: 60px;

    @media all and (max-width: 1200px) {
        width: 40vw;
    }

    @media all and (max-width: 700px) {
        width: 80vw;
    }
`

const glow = keyframes`
    0% {
        filter: grayscale(0%);
    }

    50% {
        filter: grayscale(55%);
    }

    100% {
        filter: grayscale(0%);
    }
`

const Initials = styled.div`
    width: 25vw;
    height: 25vw;
    margin-bottom: 25px;
    animation: ${glow} 5s ease-in-out infinite;

    @media all and (max-width: 1200px) {
        width: 40vw;
        height: 40vw;
    }

    @media all and (max-width: 700px) {
        width: 80vw;
        height: 80vw;
    }

    &:hover {
        animation: none;

        > div {
            border-radius: 0px !important;

            img {
                transform: scale(1.15);
            }
        }
    }

    > div {
        width: 12.5vw;
        height: 12.5vw;
        overflow: hidden;
        float: left;
        position: relative;
        transition: border-radius .5s cubic-bezier(0.16, 1, 0.3, 1);

        img {
            position: absolute;
            width: 200%;
            height: 200%;
            transition: transform .4s cubic-bezier(0.16, 1, 0.3, 1);
            transition-delay: .1s;
        }

        &.top-right img {
            right: 0px;
        }

        &.bottom-left img {
            bottom: 0px;
        }

        &.bottom-right img {
            bottom: 0px;
            right: 0px;
        }

        &.tl {
            border-top-left-radius: 6.25vw;
        }

        &.tr {
            border-top-right-radius: 6.25vw;
        }

        &.bl {
            border-bottom-left-radius: 6.25vw;
        }

        &.br {
            border-bottom-right-radius: 6.25vw;
        }

        @media all and (max-width: 1200px) {
            width: 20vw;
            height: 20vw;

            &.tl {
                border-top-left-radius: 10vw;
            }

            &.tr {
                border-top-right-radius: 10vw;
            }

            &.bl {
                border-bottom-left-radius: 10vw;
            }

            &.br {
                border-bottom-right-radius: 10vw;
            }
        }

        @media all and (max-width: 700px) {
            width: 40vw;
            height: 40vw;

            &.tl {
                border-top-left-radius: 20vw;
            }

            &.tr {
                border-top-right-radius: 20vw;
            }

            &.bl {
                border-bottom-left-radius: 20vw;
            }

            &.br {
                border-bottom-right-radius: 20vw;
            }
        }

    }
`

const Text = styled.div`
    span {
        margin-bottom: 20px;
    }
`


const TeamMember = props => {
    const initials = props.initials ? props.fields.name.split(" ").map(namePart => namePart.charAt(0)) : []
    const [ charClasses, setClasses ] = useState(false)

    useEffect(() => {
        if(charClasses === false) {
            let newCharClasses = {
                topLeft: '',
                bottomLeft: '',
                topRight: '',
                bottomRight: ''
            }
            
            initials.forEach((char, at) => {
                let _classes = {top: '', bottom: ''}

                switch(char) {
                    case "R" :
                        _classes = { top: 'tr br', bottom: 'tr' }
                        break;
                    case "S" :
                        _classes = { top: 'tl bl', bottom: 'tr br bl' }
                        break;
                    case "J" :
                        _classes = { top: 'tr', bottom: 'br bl' }
                        break;
                    case "N" :
                        _classes = { top: 'tr', bottom: '' }
                        break;
                    case "D" :
                        _classes = { top: 'tr', bottom: 'br' }
                        break;
                    case "L" :
                        _classes = { top: '', bottom: 'bl' }
                        break;
                    case "F" :
                        _classes = { top: 'tl bl', bottom: '' }
                        break;
                    case "K" :
                        _classes = { top: 'br', bottom: 'tr' }
                        break;
					case "O" :
						_classes = { top: 'tr tl', bottom: 'bl br'}
						break;
					case "A" :
						_classes = { top: 'tr tl', bottom: 'tr tl'}
						break;
                        
                    default: 
                        break;
                }

                if(at === 0) {
                    newCharClasses = {...newCharClasses, topLeft: _classes.top, bottomLeft: _classes.bottom}
                } else {
                    newCharClasses = {...newCharClasses, topRight: _classes.top, bottomRight: _classes.bottom}
                }

            })

            setClasses(newCharClasses)
        }

    }, [charClasses, initials])

    return <Wrap>
        {props.initials ?
        <Initials>
            <div className={`top-left ${charClasses.topLeft}`}>
                <img src={`${props.fields.photo.fields.file.url}?w=500&h=500`} alt={props.fields.name} />
            </div>
            <div className={`top-right ${charClasses.topRight}`}>
                <img src={`${props.fields.photo.fields.file.url}?w=500&h=500`} alt={props.fields.name} />
            </div>
            <div className={`bottom-left ${charClasses.bottomLeft}`}>
                <img src={`${props.fields.photo.fields.file.url}?w=500&h=500`} alt={props.fields.name} />
            </div>
            <div className={`bottom-right ${charClasses.bottomRight}`}>
                <img src={`${props.fields.photo.fields.file.url}?w=500&h=500`} alt={props.fields.name} />
            </div>
        </Initials> : null}
        <Text>
            <span className="name">{props.fields.name}</span>
            <div className="description">{documentToReactComponents(props.fields.description, renderOptions)}</div>
        </Text>
        </Wrap>
}

export default TeamMember