import React from 'react'
import vars from '../../helpers/vars'
import styled from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'

const Wrapper = styled.div`
	display: flex;
	margin-bottom: 150px;
	width: 100%;
	box-sizing: border-box;
	max-width: calc(${vars.maxWidth} + 20%);
	padding: ${vars.defaultPadding.y} ${vars.defaultPadding.x};
	margin: 0px auto 150px auto;

	justify-content: space-between;

	> div {
		width: 100%;

		> iframe {
			width: 100%;
		}
	}
`


const BlockVideo = props => {
	//const options = props.fields.options || false
	const playerWidth = vars.maxWidth.split('px')[0] * 1.2
	/*
	const controls = options ? !options.includes('Hide controls') : true
	const muted = options ? !options.includes('Autoplay') : true
	const autoplay = options ? options.includes('Autoplay') : false
	const loop = options ? options.includes('Loop') : false */


	return <Wrapper>
		<Vimeo 
			video={props.fields.vimeoId}
			showPortrait={false}
			showTitle={false}
			showByline={false}
			width={playerWidth} />
	</Wrapper>
}

export default BlockVideo