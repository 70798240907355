import React, { createContext, useState, useEffect } from 'react'
import { createClient } from 'contentful'

export const ContentContext = createContext({})
const client = createClient({
	space: '9s43qzbur3l9',
	accessToken: 'gzDWCe8DDMzFk9wC2iytImXy3PzOW5MGnwWbpVr2d1g',
	environment: 'master'
})

const ContentContextProvider = (props) => {
	const [ content, setContent ] = useState(false)
	const [ pageContent, setPageContent ] = useState(false)
	const [ fetching, setFetching ] = useState({main: false, page: false})

	useEffect(() => {
		if(content === false && !fetching.main) {
			setFetching({...fetching, main: true})
			client.getEntries({content_type: 'home', include: 10, resolveLinks: true})
				.then((res) => {
					setContent(res.items[0])
					setFetching({...fetching, main: false})
				})
				.catch(console.error)

		}
	}, [content, fetching])

	useEffect(() => {
		if(pageContent === false && !fetching.page) {
			setFetching({...fetching, page: true})
			client.getEntries({content_type: 'page', include: 10, resolveLinks: true})
			.then(res => {
				setPageContent(res.items)
				setFetching({...fetching, page: false})
			})
			.catch(console.error)
		}
	}, [pageContent, fetching])

	const findPageBySlug = slug => {
		if(!pageContent) {
			return false
		}

		const content = pageContent.find(page => {
			return page.fields.slug === slug
		})

		return content

	}


	return <ContentContext.Provider value={{content, findPageBySlug, pageContent}} >
		{props.children}
	</ContentContext.Provider>
}

export default ContentContextProvider