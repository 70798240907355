import React, { useContext, useEffect, useRef, useState, useLayoutEffect, useCallback } from 'react'
import { ScrollContext, ChapterSplash } from './Chapters'
import BezierEasing from 'bezier-easing'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'

const Wrapper = styled.div`
	height: 100%;
	width: 100vw;
	display: flex;
	justify-content: space-around;
	
	div.optimal {
		width: 100%;
        height: 100vh;
		position: relative;
		z-index: 3;
	}
`

const StyledCanvas = styled.canvas`
	left: 0px;
	z-index: 2;
`

const VideoWrapper = styled.div`
	z-index: 1;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;

		&.fixed {
			overflow: hidden;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			position: fixed;
		}

		iframe {
			width: 100vw;
			height: 56.25vw; // 16:9 ratio
			min-height: 100vh;
			min-width: 177.77vh; // 16:9
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`

const ChapterFilm = props => {
    const { progress, entering, scrolling, screensInWrapper, setScreens, inView } = useContext(ScrollContext)
	const easing = BezierEasing(0.87, 0, 0.13, 1)
	const canvasRef = useRef(undefined)
	const videoRef = useRef(undefined)
	const wrapRef = useRef(undefined)
	const [ viewWidth, setViewWidth ] = useState(window.innerWidth)
	const [ viewHeight, setViewHeight ] = useState(window.innerHeight)
	const [ resizing, setResizing ] = useState(null)
	const catVideoId = '515678320'
	const girlVideoId = '515679471'
	const [ videoId, setVideoId ] = useState(girlVideoId)

	// Chapter basics
	useEffect(() => {
		if(!screensInWrapper) {
			setScreens(props.height ? props.height : 1)
		}
	})

	// Responsive / resize
	useLayoutEffect(() => {
		const resizeListener = () => {
			clearTimeout(resizing)
			setResizing(setTimeout(() => {
				if(wrapRef.current) {
					setViewWidth(window.innerWidth)
					setViewHeight(window.innerHeight)
				}
			}, 1000))
		  };
		  // set resize listener
		  window.addEventListener('resize', resizeListener);
	  
		  // clean up function
		  return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener);
		  }
	}, [resizing, viewWidth, viewHeight])

	const upHandler = ({ key }) => {
		if(key === 'c') {
			setVideoId(catVideoId)
		}
	}

	// KeyListener
	useEffect(() => {
		window.addEventListener('keyup', upHandler);
		// Remove event listeners on cleanup
		return () => {
		  window.removeEventListener('keyup', upHandler);
		};
	  }, []);


	// Helper
	const peakProgress = (pr, start, end) => {
        if(pr < start || pr > end) return 0

        const peak = (start + end) / 2

        if(pr <= peak) {
            return (pr - start) / (peak - start)
        } else {
            return 1 - ((pr - peak) / (end - peak))
        }
	}

	// Helper
	const subSection = useCallback((pr, start, end, disableEasing=false) => {
		if(pr < start) return 0
		if(pr >= end) return 1

		return disableEasing ? (pr - start) / (end - start) : easing((pr - start) / (end - start))
	}, [easing])

	// Helper
	const inAndOut = useCallback((pr, inEnd, outStart) => {
		if(pr > inEnd && pr < outStart) return 0
		if(pr < .5) {
			return(.7 - subSection(pr, 0, inEnd, true))
		} else {
			return(subSection(pr, outStart, 1, true) * .7)
		}
	}, [subSection])


	// Vignette
	useEffect(() => {
		if(canvasRef.current !== undefined && canvasRef.current !== null) {
			const canv = canvasRef.current
			const ctx = canv.getContext('2d')
			const _progress = progress / .6667
			ctx.clearRect(0, 0, canv.width, canv.height)
			ctx.rect(0, 0, canv.width, canv.height);

			const x = canv.width / 2
			const y = (.9 - (_progress * .8)) * canv.height
			const gradientInnerOpacity = inAndOut(_progress, 0.1, 0.9)
			const r0 = (canv.width * .096) + (peakProgress(_progress, 0, 1) * (canv.width / 2.2))
			const r1 = r0 * 3
			const gradient = ctx.createRadialGradient(x, y, r0, x, y, r1)
			

			gradient.addColorStop(0, `rgba(0, 0, 0, ${gradientInnerOpacity})`)
			gradient.addColorStop(1, 'rgba(0, 0, 0, 1)')

			ctx.fillStyle = gradient
			ctx.fill()

		} 
	}, [progress, entering, inAndOut])



	// Render
	return inView ? <Wrapper ref={wrapRef}>
		<div className="optimal">
			<ChapterSplash>
			<h2>{props.fields.title}</h2>
			<h3>{props.fields.tagline}</h3>
			{documentToReactComponents(props.fields.body)}
			</ChapterSplash>
		</div>
		
		<StyledCanvas ref={canvasRef} width={viewWidth} height={viewHeight} style={{
			position: `${scrolling && progress < .6667 ? 'fixed' : 'absolute'}`, 
			top: `${scrolling && progress < .6667 ? '0px' : progress > .6667 ? 'auto' : '0px'}`, 
			bottom: `${scrolling && progress > .6667 ? '0px' : 'auto'}`
		}} />
		<VideoWrapper ref={videoRef} style={{
			opacity: 1
		}}>
			<Vimeo 
				video={`${videoId}`}
				className={`${scrolling && progress < .6667 ? 'fixed' : ''}`}
				style={{
					top: `${scrolling && progress < .6667 ? '0px' : progress > .6667 ? 'auto' : '0px'}`, 
					bottom: `${scrolling && progress > .6667 ? '0px' : 'auto'}`,
					opacity: `${1 - subSection(progress, .55, 1)}`
				}}
				controls={false} 
				showPortrait={false} 
				showTitle={false} 
				muted={true} 
				autoplay={true} 
				background={true} 
				showByline={false} 
				loop={true} />
		</VideoWrapper>
		
		</Wrapper>: null
}

export default ChapterFilm