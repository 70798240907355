import React, { useState, createContext, useLayoutEffect } from 'react'

export const LayoutContext = createContext()

const LayoutContextProvider = props => {
    const [ viewportHeight, setViewportHeight ] = useState(Math.max(document.documentElement.clientHeight, window.innerHeight || 0))
    const [ viewportWidth, setViewportWidth ] = useState(Math.max(document.documentElement.clientWidth, window.innerWidth || 0))
    const [ resizing, setResizing ] = useState(null)
	const [ visualRealEstate, setRealEstate ] = useState(Math.floor((viewportWidth * viewportHeight) / 1000))

    useLayoutEffect(() => {
		const resizeListener = () => {

			clearTimeout(resizing)
			const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
			const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

			if(w === viewportWidth && h !== viewportHeight) {
				// this is a height-only change, likely a mobile safari scroll action
				// we're handling the resize immediately, and updating the scroll position to
				// avoid jumping
				setViewportWidth(w)
				setViewportHeight(h)
				setRealEstate(Math.floor((w * h) / 1000))
			} else {
				setResizing(setTimeout(() => {
					setViewportWidth(w)
					setViewportHeight(h)
					setRealEstate(Math.floor((w * h) / 1000))
				}, 1000))
			}
		  }

		  // set resize listener
		  window.addEventListener('resize', resizeListener);
	  
		  // clean up function
		  return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener);
		  }
    }, [resizing, viewportWidth, viewportHeight])
    
    return (
        <LayoutContext.Provider value={{viewportHeight, viewportWidth, visualRealEstate}}>
            {props.children}
        </LayoutContext.Provider>
    )
}

export default LayoutContextProvider