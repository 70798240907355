import React, { createContext, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'

export const NavigationContext = createContext({})
ReactGA.initialize('UA-190611247-1')
ReactGA.pageview(window.location.pathname)

const NavigationContextProvider = (props) => {
  	const { pathname, hash } = useLocation()
  	const history = useHistory()
  	const [activePath, setActive] = useState(`${pathname}${hash ? hash : ""}`)

	


	const navigate = (url) => {
    	setActive(url)
		history.push(url)
		ReactGA.pageview(url)
  	}

	return (
		<NavigationContext.Provider value={{ navigate, activePath, setActive }}>
			{props.children}
		</NavigationContext.Provider>
	)
}

export default NavigationContextProvider
