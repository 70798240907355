import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import './style/index.css'
import {
	BrowserRouter as Router,
	Switch,
	Route
  } from "react-router-dom"
import WebFont from 'webfontloader'
import Background from './components/Background'
import Nav from './components/Nav'
import styled from 'styled-components'
import vars from './helpers/vars'
import LanguageContextProvider from './context/LanguageContext'
import ContentContextProvider from './context/ContentContext'
import ThemeContextProvider from './context/ThemeContext'
import LayoutContextProvider, { LayoutContext } from './context/LayoutContext'
import NavigationContextProvider from './context/NavigationContext'
import Loading from './components/Loading'
import Home from './components/Home'
import About from './components/About'
import Work from './components/Work'


const IndexDiv = styled.div`
	color: ${vars.colors.white};
	padding-top: 200px;
	margin: 0px auto;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.medium {
		font-size: 90%;
	}

	&.small {
		font-size: 85%;
	}

	&.tiny {
		font-size: 80%;
	}

	a {
		color: ${vars.colors.alternative};
	}

	div.optimal {
		width: 100%;
		box-sizing: border-box;
		max-width: ${vars.maxWidth};
		padding: ${vars.defaultPadding.y} ${vars.defaultPadding.x};
		margin: 0px auto;

		div.text {
			max-width: 75%;

			@media all and (max-width: 1024px) {
				max-width: 100%;
			}
		}

		&.primary {
			color: ${vars.colors.primary};
		}

		&.alt {
			> * {
				color: ${vars.colors.alternative};
			}
		}

		&.top-gradient {
			position: relative;

			::before {
				content: "";
				display: block;
				width: 200vw;
				height: 20vh;
				background-color: green;
				position: absolute;
				top: -1;
				left: -50vw;
				z-index: -1;
				background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
			}
		}

		&.bottom-space {
			padding-bottom: 120px;
		}
	}

	div.full {
		width: 100%;
		left: 0px;
	}
`

const Index = props => {
	const { visualRealEstate } = useContext(LayoutContext)
	let realEstateClass = 'large'
	if(visualRealEstate < 900) {
		realEstateClass = 'medium'
	}
	if(visualRealEstate < 600) {
		realEstateClass = 'small'
	}
	if(visualRealEstate < 400) {
		realEstateClass = 'tiny'
	}
	return <IndexDiv className={realEstateClass}>{props.children}</IndexDiv>
}


WebFont.load({
	typekit: {
		id: 'hzi5cqe'
	}
});

ReactDOM.render(
  <React.StrictMode>
	  <LayoutContextProvider>
	  <LanguageContextProvider>
		  <ThemeContextProvider>
			<ContentContextProvider>
					<Router>
            <NavigationContextProvider>
                <Background />
                <Loading />
                <Index>
                  <Nav />
                  <Switch>
					  <Route path="/work">
						  <Work />
					  </Route>
                    <Route path="/about" exact>
                      <About />
                    </Route>
                    <Route path="/" exact>
                      <Home />
                    </Route>
                    <Route path="/en">
                    </Route>
                  </Switch>
                </Index>
            </NavigationContextProvider>
					</Router>
				</ContentContextProvider>
			</ThemeContextProvider>
		</LanguageContextProvider>
		</LayoutContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
