import React, { useContext,  useEffect, useRef, useState } from 'react'
import { ScrollContext } from '../Chapters'
import styled from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'
import BezierEasing from 'bezier-easing'
import Bar35 from '../../assets/bar35.svg'
import NomNom from '../../assets/nomnom.svg'
import FiftyFifty from '../../assets/fifty-fifty.svg'
import Lux from '../../assets/lux.svg'

const Wrap = styled.div`
	width: calc(100vw - 40px);
	height: 150vh;
	padding: 20vh 0px;
	display: flex;
	transform: translateY(-20vh);

	@media all and (max-width: 1024px) {
		flex-direction: column;
	}
`

const Col = styled.div`
	width: 50%;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	@media all and (max-width: 1024px) {
		width: 100%;
	}

	> div {
		width: 100%;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		position: relative;
		overflow: hidden;
		background-color: #000;
	}

	img {
		z-index: 1;
		max-height: 250px; 
		max-width: 33%;

		&.nomnom {
			max-height: 210px;
		}
	}

	div.vimeo-wrapper {
		position: absolute;
		flex-basis: 100%;
		top: 0px;
		left: 0px;
	}

	iframe {
		opacity: .6;
		z-index: 0;
	}

	&.darker iframe {
		opacity: .4;
	}
`

const Blocks = props => {
	const { screensInWrapper, setScreens, progress, entering } = useContext(ScrollContext)
	const [ colWidth, setWidth ] = useState(false)
	const colRef = useRef(undefined)
	const easing = BezierEasing(0.65, 0, 0.35, 1)
	const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
	const windowHeight = window.innerHeight
	const linearScroll = windowWidth < 1024

	useEffect(() => {
		if(!screensInWrapper) {
			const screens = windowWidth > 1024 ? 1 : (windowWidth * 1.86) / windowHeight
			setScreens(screens)
		}
	})

	useEffect(() => {
		if(!colWidth && colRef.current !== undefined) {
			setWidth(colRef.current.clientWidth - 40)
		}
	}, [colWidth])

	const subSection = (pr, start, end) => {
		if(pr < start) return 0
		if(pr >= end) return 1

		return easing((pr - start) / (end - start))
	}


	return <Wrap>
		<Col ref={colRef}>
			{colWidth ? 
			<React.Fragment>
				<div style={{
					transform: `translateY(-${linearScroll ? ((entering + progress) * 1) * 250 : ((entering + progress) * .7) * 250}px)`,
					height: `${colWidth / 1.7778}px`,
					filter: `grayscale(${100 - (subSection(entering, .4, .8) * 100)}%)`
				}}>
					<Vimeo className="vimeo-wrapper" video='463366123' width={colWidth} controls={false} showPortrait={false} showTitle={false} muted={true} autoplay={true} background={true} showByline={false} loop={true}/>
					<img src={Bar35} alt="" />
				</div> 
				<div style={{
					transform: `translateY(-${linearScroll ? ((entering + progress) * 1) * 250 : ((entering + progress) * 1) * 250}px)`,
					marginTop: linearScroll ? '4vh' : '10vh',
					height: `${colWidth / 1.7778}px`,
					filter: `grayscale(${100 - (subSection(entering, .5, .9) * 100)}%)`
				}}>
					<Vimeo className="vimeo-wrapper darker" video='463389994' width={colWidth} controls={false} showPortrait={false} showTitle={false} muted={true} autoplay={true} background={true} showByline={false} loop={true}/>
					<img src={FiftyFifty} alt="" />
				</div>
			</React.Fragment> : null}
		</Col>
		<Col>
		{colWidth ? 
			<React.Fragment>
				<div style={{
					transform: `translateY(-${linearScroll ? ((entering + progress) * 1) * 250 : ((entering + progress) * 1.3) * 250}px)`,
					marginTop: linearScroll ? '0' : '20vh',
					height: `${colWidth / 1.7778}px`,
					filter: `grayscale(${100 - (subSection(entering, .45, .85) * 100)}%)`
				}}>
					<Vimeo className="vimeo-wrapper" video='463391296' width={colWidth} controls={false} showPortrait={false} showTitle={false} muted={true} autoplay={true} background={true} showByline={false} loop={true}/>
					<img className="nomnom" src={NomNom} alt="" />
				</div> 
				<div style={{
					transform: `translateY(-${linearScroll ? ((entering + progress) * 1) * 250 : ((entering + progress) * 1.6) * 250}px)`,
					marginTop: linearScroll ? '4vh' : '10vh',
					height: `${colWidth / 1.7778}px`,
					filter: `grayscale(${100 - (subSection(entering, .55, .9) * 100)}%)`
				}}>
					<Vimeo className="vimeo-wrapper darker" video='463392448' width={colWidth} controls={false} showPortrait={false} showTitle={false} muted={true} autoplay={true} background={true} showByline={false} loop={true}/>
					<img src={Lux} alt="" />
				</div>
			</React.Fragment> : null}
		</Col>
	</Wrap>
}

export default Blocks